<template>
  <MovePreferredDateField v-on="$listeners" />
</template>

<script>
import MovePreferredDateField from 'chimera/all/components/form/fields/movePreferredDate/MovePreferredDateField'

export default {
  name: 'MovePreferredDateFormPart',

  components: {
    MovePreferredDateField,
  },
}
</script>
