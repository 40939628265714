<template>
  <MoveDeAssemblyWorkRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import MoveDeAssemblyWorkRadioField from 'chimera/all/components/form/fields/moveDeAssemblyWork/MoveDeAssemblyWorkRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'MoveDeAssemblyWorkFormPart',

  components: {
    MoveDeAssemblyWorkRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(this.$t('yes'), this.$t('yes'), this.$t('yes')),
        new Selectable(this.$t('no'), this.$t('no'), this.$t('no')),
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "yes": "Ja",
    "no": "Nee"
  },
  "nl-BE": {
    "yes": "Ja",
    "no": "Nee"
  },
  "es-ES": {
    "yes": "Si",
    "no": "No"
  },
  "de-DE": {
    "yes": "Ja",
    "no": "Nein"
  },
  "fr-BE": {
    "yes": "Oui",
    "no": "Non"
  },
  "fr-FR": {
    "yes": "Oui",
    "no": "Non"
  }
}
</i18n>
