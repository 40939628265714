<template>
  <FormModal title="Condition d'emballage" show-back-btn>
    <template #body>
      <PackingConditionsAssemblyDateFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PackingConditionsAssemblyDateFormStep from '~/components/form/steps/packingConditionsAssemblyDate/PackingConditionsAssemblyDateFormStep'

export default {
  components: {
    PackingConditionsAssemblyDateFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Détails du déménagement',
      headDescription: 'Fournissez les détails du déménagement.',
      path: '/quotes-requests/details-du-demenagement',
      progressValue: 45,
      checkoutStep: 4,
    }
  },
}
</script>
