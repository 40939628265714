<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <MovePackingConditionsFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">
        {{ $t('assemblyDisassemblyWork') }}
      </h2>

      <MoveDeAssemblyWorkFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">
        {{ $t('preferredDate') }}
      </h2>

      <MovePreferredDateFormPart @result="onResult" @change="onChange" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import MovePackingConditionsFormPart from 'chimera/moving/components/form/parts/movePackingConditions/MovePackingConditionsFormPart'
import MoveDeAssemblyWorkFormPart from 'chimera/moving/components/form/parts/moveDeAssemblyWork/MoveDeAssemblyWorkFormPart'
import MovePreferredDateFormPart from 'chimera/moving/components/form/parts/movePreferredDate/MovePreferredDateFormPart'
import NextStep from '~/pages/quotes-requests/vos-coordonnees'

export default {
  name: 'PackingConditionsAssemblyDateFormStep',

  components: {
    MovePackingConditionsFormPart,
    MoveDeAssemblyWorkFormPart,
    MovePreferredDateFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "assemblyDisassemblyWork": "Montage- en demontage werkzaamheden",
    "preferredDate": "Voorkeursdatum"
  },
  "nl-BE": {
    "assemblyDisassemblyWork": "Montage- en demontage werkzaamheden",
    "preferredDate": "Voorkeursdatum"
  },
  "es-ES": {
    "assemblyDisassemblyWork": "Trabajos de montaje y desmontaje",
    "favoriteDate": "Fecha preferida"
  },
  "de-DE": {
    "assemblyDisassemblyWork": "Montage- und Demontagearbeiten",
    "preferredDate": "Bevorzugtes Datum"
  },
  "fr-BE": {
    "assemblyDisassemblyWork": "Travaux de montage et démontage",
    "preferredDate": "Date de préférence"
  },
  "fr-FR": {
    "assemblyDisassemblyWork": "Travaux de montage et démontage",
    "preferredDate": "Date de préférence"
  }
}
</i18n>
